import {AnalyticsApiQuery, DateRange, Filter, Period, Sort} from '@/models/analytics-api';
import {
    DateContextEvent,
    DateContextPeriod,
    DateContextTransaction,
    instanceOfDateContextPeriod,
} from '@/models/date-context';


export class AnalyticsApiQueryBuilder {
    private dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
    private segments?: string[];
    private dimensions?: string[];
    private metrics?: string[];
    private filters?: Filter[];
    private includeEmptyRows?: boolean;
    private sort?: Sort[];
    private maxResults?: number;
    private startIndex?: number;

    constructor(options?: AnalyticsApiQueryBuilderOptions) {
        if(options) {
            this.dateContext = options.dateContext;
            this.segments = options.segments;
            this.dimensions = options.dimensions;
            this.metrics = options.metrics;
            this.filters = options.filters;
            this.includeEmptyRows = options.includeEmptyRows;
            this.sort = options.sort;
            this.maxResults = options.maxResults;
            this.startIndex = options.startIndex;
        }
    }

    public build(): AnalyticsApiQuery {
        let query: AnalyticsApiQuery = {
            dimensions: this.dimensions ? this.dimensions.map((d) => ({name: d})) : [],
            metrics: this.metrics ? this.metrics.map((m) => ({name: m})) : [],
            segments: this.segments ? this.segments.map((s) => ({id: s})) : [],
            filters: this.filters,
            sort: this.sort,
            includeEmptyRows: this.includeEmptyRows !== undefined ? this.includeEmptyRows : undefined,
            maxResults: this.maxResults !== undefined ? this.maxResults : undefined,
            startIndex: this.startIndex !== undefined ? this.startIndex : undefined,
        };

        const dateRange = this.dateContext ? this.getDateRanges(this.dateContext) : null;
        const periods = this.dateContext ? this.getPeriods(this.dateContext) : null;
        if (dateRange) {
            query.dateRanges = dateRange;
        }
        if (periods) {
            query.periods = periods;
        }

        return query;
    }

    private getDateRanges(dateContext: DateContextPeriod|DateContextEvent|DateContextTransaction): DateRange[]|undefined {
        if(instanceOfDateContextPeriod(dateContext)) {
            return;
        }
        const ranges: DateRange[] = [{startDate: dateContext.range.min, endDate: dateContext.range.max}];
        if(dateContext.compareTo && dateContext.compareTo.length > 0) {
            ranges.push({startDate: dateContext.compareTo[0].min, endDate: dateContext.compareTo[0].max});
        }
        return ranges;
    }

    private getPeriods(dateContext: DateContextPeriod|DateContextEvent|DateContextTransaction): Period[]|undefined {
        if(!instanceOfDateContextPeriod(dateContext)) {
            return;
        }
        const periods: Period[] = [{id: dateContext.periodId}];
        if(dateContext.compareTo && dateContext.compareTo.length > 0) {
            periods.push({id: dateContext.compareTo[0]});
        }
        return periods;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Setters
    ////////////////////////////////////////////////////////////////////////////////////////////////
    public setDateContext(dateContext: DateContextPeriod|DateContextEvent|DateContextTransaction): AnalyticsApiQueryBuilder {
        this.dateContext = dateContext;
        return this;
    }

    public setSegments(segments: string[]): AnalyticsApiQueryBuilder {
        this.segments = segments;
        return this;
    }

    public setDimensions(dimensions: string[]): AnalyticsApiQueryBuilder {
        this.dimensions = dimensions;
        return this;
    }

    public setMetrics(metrics: string[]): AnalyticsApiQueryBuilder {
        this.metrics = metrics;
        return this;
    }

    public setFilters(filters: Filter[]): AnalyticsApiQueryBuilder {
        this.filters = filters;
        return this;
    }

    public setSort(sort: Sort[]): AnalyticsApiQueryBuilder {
        this.sort = sort;
        return this;
    }

    public setMaxResults(maxResults: number): AnalyticsApiQueryBuilder {
        this.maxResults = maxResults;
        return this;
    }

    public setStartIndex(startIndex: number): AnalyticsApiQueryBuilder {
        this.startIndex = startIndex;
        return this;
    }

}

export interface AnalyticsApiQueryBuilderOptions {
    dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
    segments?: string[];
    dimensions?: string[];
    metrics?: string[];
    filters?: Filter[];
    includeEmptyRows?: boolean;
    sort?: Sort[];
    maxResults?: number;
    startIndex?: number;
}
