import { ActionTree } from 'vuex';
import axios from 'axios';
import { AudienceState } from './models';
import { RootState } from '@/store/models';
import {AnalyticsApiQueryBuilder} from '@/util/AnalyticsApiQueryBuilder';
// import analyticsApi from '@/services/DummyAnalyticsApiService';
import analyticsApi from '@/services/AnalyticsApiService';


export const actions: ActionTree<AudienceState, RootState> = {
//     fetchData({ commit }): any {
//         // axios({
//         //     url: 'https://....'
//         // }).then((response) => {
//         //     const payload: User = response && response.data;
//         //     commit('profileLoaded', payload);
//         // }, (error) => {
//         //     console.log(error);
//         //     commit('profileError');
//         // });
// console.log('fetch audience data');
//     }


    async fetchAgeData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.customerAgeBracket'],
            metrics: ['te.uniqueCustomers'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-audience-age' );
        commit('setAgeData', data);
    },

    async fetchGenderData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.customerGender'],
            metrics: ['te.uniqueCustomers'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-audience-gender' );
        commit('setGenderData', data);
    },

    async fetchGeoData({ commit, rootState } ): Promise<void> {
        // const queryBuilder = new AnalyticsApiQueryBuilder({
        //     dateContext: rootState.dateContext,
        //     segments: rootState.selectedSegments,
        //     paymentMethodDimensions: ['te.customerType'],
        //     paymentMethodMetrics: ['te.uniqueCustomers'],
        //     filters: [{eventId: {equals: eventId}}],
        // });
        //
        // const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'audience-geo' );
        // commit('setGeoData', data);
    },

};
