import { GetterTree } from 'vuex';
import { SegmentState } from './models';
import { RootState } from '@/store/models';
import {IntegrationConfig, Segment, SegmentIntegration, SegmentSummery} from '@/models/segment';
import {MailchimpList} from '@/models/mailchimp';

export const getters: GetterTree<SegmentState, RootState> = {
    segments(state): SegmentSummery[] {
        return state.segments;
    },

    segment(state): Segment|null {
        return state.segment;
    },

    segmentIntegrations(state): SegmentIntegration[] {
        return state.integrations;
    },

    integrationConfigs(state): IntegrationConfig[] {
        return state.integrationConfigs;
    },

    mailchimpLists(state): MailchimpList[] {
        return state.mailchimpLists;
    },

};
