import analyticsApi from '@/services/AnalyticsApiService';
import {ArrayConditionOption} from '@/models/condition';

class ConditionOptionService {


    public async getSalesChannelOptions(): Promise<ArrayConditionOption[]> {
//     return [
//         {id: '1', label: 'Website'},
//         {id: '2', label: 'Box Office'},
//     ];s
        const response = await analyticsApi.getOptions('sales-channel');
        return response.data;
    }

    public async getRegisterOptions(): Promise<ArrayConditionOption[]> {
        const response = await analyticsApi.getOptions('register');
        return response.data;
    }

    public async getVisitedEventOptions(): Promise<ArrayConditionOption[]> {
        // return [
        //     {id: '1', label: 'At Eternity\'s Gate (2019-01-26 @ Pathe 1)'},
        //     {id: '2', label: 'High Life (2019-01-27 @ Pathe 4)'},
        //     {id: '3', label: 'Sofia (2019-02-02 @ Kino 4)'},
        //     {id: '4', label: 'Sons of Denmark (2019-01-26 @ Cinerama 7)'},
        //     {id: '5', label: 'The River (2019--1-29 @ Kino 1)'},
        //     // {id: '5', label: ''},
        // ];
        const response = await analyticsApi.getOptions('event');
        return response.data;
    }

    public async getVisitedEventCategoryOptions(): Promise<ArrayConditionOption[]> {
        // return [
        //     {id: '1', label: 'Regular'},
        //     {id: '2', label: 'Special'},
        //     {id: '3', label: 'Press & Industry screening'},
        // ];
        const response = await analyticsApi.getOptions('event-category');
        return response.data;
    }

    public async getVisitedPeriodOptions(): Promise<ArrayConditionOption[]> {
        // return [
        //     {id: '1', label: 'Edition 2018'},
        //     {id: '2', label: 'Edition 2019'},
        //     {id: '3', label: 'Edition 2020'},
        // ];
        const response = await analyticsApi.getOptions('period');
        return response.data;
    }

    public async getCurrencyOptions(): Promise<ArrayConditionOption[]> {
        // return [
        //     {id: 'EUR', label: 'Euro'},
        //     {id: 'USD', label: 'US Dollar'},
        //     {id: 'FLKI', label: 'FLoekie'},
        // ];
        const response = await analyticsApi.getOptions('currency');
        return response.data;
    }

    public async getSegmentOptions(): Promise<ArrayConditionOption[]> {
        // return [
        //     {id: '1', label: 'Pro'},
        //     {id: '2', label: 'Predicted: churn next period'},
        // ];
        const response = await analyticsApi.getOptions('segment');
        return response.data;
    }
}

export default new ConditionOptionService();
