import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AudienceState } from './models';
import { RootState } from '@/store/models';

export const state: AudienceState = {
    // data: {uniqueTicketBuyers: 5},
    // user: undefined,
    // error: false
    age: null,
    gender: null,
    geo: null,
};

const namespaced: boolean = true;

export const audience: Module<AudienceState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
