import { GetterTree } from 'vuex';
import { AudienceState } from './models';
import { RootState } from '@/store/models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const getters: GetterTree<AudienceState, RootState> = {
    // fullName(state): string {
    //     const { user } = state;
    //     const firstName = (user && user.firstName) || '';
    //     const lastName = (user && user.lastName) || '';
    //     return `${firstName} ${lastName}`;
    // }
    // uniqueTicketBuyers(state): number {
    //     const { data } = state;
    //     return data && data.uniqueTicketBuyers || 0;
    // }

    ageData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.age;
    },
    genderData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.gender;
    },
    geoData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.geo;
    },

};
