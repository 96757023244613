import { MutationTree } from 'vuex';
import { FinancialState } from './models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const mutations: MutationTree<FinancialState> = {

    setSalesChannelData(state, data: AnalyticsApiQueryResponse) {
        state.salesChannel = data;
    },

    setSalesChannelLedgerData(state, data: AnalyticsApiQueryResponse) {
        state.salesChannelLedger = data;
    },

    setRegisterData(state, data: AnalyticsApiQueryResponse) {
        state.register = data;
    },

    setRegisterLedgerData(state, data: AnalyticsApiQueryResponse) {
        state.registerLedger = data;
    },

    setPriceTypesData(state, data: AnalyticsApiQueryResponse) {
        state.priceType = data;
    },

    setEventPriceTypesData(state, data: AnalyticsApiQueryResponse) {
        state.eventPriceTypes = data;
    },



    setPaymentTimeSeriesData(state, data: AnalyticsApiQueryResponse) {
        state.paymentTimeSeries = data;
    },

    setPaymentMethodData(state, data: AnalyticsApiQueryResponse) {
        state.paymentMethod = data;
    },

    setPaymentServiceProviderData(state, data: AnalyticsApiQueryResponse) {
        state.paymentServiceProvider = data;
    },

    setPaymentMethodDetailData(state, data: AnalyticsApiQueryResponse) {
        state.paymentMethodDetail = data;
    },

    setPaymentServiceProviderDetailData(state, data: AnalyticsApiQueryResponse) {
        state.paymentServiceProviderDetail = data;
    },

};
