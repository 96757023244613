import {TranslateResult} from 'vue-i18n';



export enum FactType {
    string = 'string',
    number = 'number',
    date = 'date',
    array = 'array',
    currencyAmount = 'currencyAmount',
}



export interface Condition {
    id: string;
    operator: string|null;
}

export interface StringCondition extends ValueCondition {
    value: string;
}

export interface NumberCondition extends ValueCondition {
    value: number;
}

export interface DateCondition extends ValueCondition {
    value: string;
}

export interface ArrayCondition extends ValueCondition {
    value: string[];
}

export interface ObjectCondition extends ValueCondition {
    value: object;
}

export interface ValueCondition extends Condition {
    fact: string|null;
    value: any;
}

export interface GroupCondition extends Condition {
    conditions: Array<ValueCondition|GroupCondition>;
}

export interface CurrencyAmountCondition extends ValueCondition {
    value: {
        currencyCode: string;
        amount: number;
    };
}



export interface FactOption {
    id: string;
    type: FactType;
    label: string | TranslateResult;
    operators: OperatorOption[];
    // options?: Array<{id: string, name: string}>;
    options?(): ArrayConditionOption[] | Promise<ArrayConditionOption[]>;
}

export interface FactOptionGroup {
    // id: string;
    label: string | TranslateResult;
    facts: FactOption[];
}

export interface OperatorOption {
    id: string;
    label: string | TranslateResult;
}

export interface ArrayConditionOption {
    id: string;
    label: string;
}

export function instanceOfGroupCondition(object: any): object is GroupCondition {
    // return object && 'conditions' in object;
    return object && object.conditions;
}

export function instanceOfValueCondition(object: any): object is ValueCondition {
    return object && 'value' in object;
}

export function instanceOfCurrencyAmount(object: any): object is CurrencyAmountCondition {
    return object && object.value && object.value.currencyCode && object.value.amount;
}

