import { ActionTree } from 'vuex';
import { EventState } from './models';
import { RootState } from '@/store/models';
import analyticsApi from '@/services/AnalyticsApiService';
// import analyticsApi from '@/services/DummyAnalyticsApiService';
import {AnalyticsApiQueryBuilder} from '@/util/AnalyticsApiQueryBuilder';
// import {Period} from '@/models/date-context';


export const actions: ActionTree<EventState, RootState> = {

    async fetchOverviewKpiData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [],
            metrics: ['te.uniqueCustomers', 'te.sold', 'te.revenue', 'te.visitedEvents', 'te.visitedPeriods', 'te.groupSize', 'te.eventOccupancyRate'],
            filters: [{key: 'te.eventOutline', operator: 'notEquals', value: null}],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-overview-kpi' );
        commit('setOverviewKpiData', data);
    },

    async fetchOverviewTimeSeriesData({ commit, rootState }, {timeDimension, metric} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [timeDimension],
            metrics: [metric],
            filters: [{key: 'te.eventOutline', operator: 'notEquals', value: null}],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-overview-time-series' );
        commit('setOverviewTimeSeriesData', data);
    },

    async fetchOverviewTopEventsData({ commit, rootState }, {eventStartDate} ): Promise<void> {
        const filter = eventStartDate ? [{key: 'te.eventStartDate', operator: 'equals', value: eventStartDate}] : undefined;
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            // dimensions: ['te.eventOutline', 'te.eventId'],
            dimensions: ['te.eventTitle', 'te.eventLocation', 'te.eventStartDateHourMinute', 'te.eventId'],
            // metrics: ['te.sold', 'te.revenue', 'te.eventOccupancyRate'],
            metrics: ['te.eventSold', 'te.eventReserved', 'te.eventCapacity', 'te.eventOccupancyRate', 'te.eventRevenue', 'te.salesVelocityDays'],
            filters: filter,
            includeEmptyRows: true,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-overview-top-events' );
        commit('setOverviewTopEventsData', data);
    },

    // async fetchOverviewSalesPerEventCategoryData({ commit, rootState } ): Promise<void> {
    //     const queryBuilder = new AnalyticsApiQueryBuilder({
    //         dateContext: rootState.dateContext,
    //         segments: rootState.selectedSegments,
    //         dimensions: ['te.eventCategory'],
    //         metrics: ['te.sold'],
    //     });
    //
    //     const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-overview-sales-per-event-category' );
    //     commit('setOverviewSalesPerEventCategoryData', data);
    // },
    //
    // async fetchOverviewOccupancyPerEventCategoryData({ commit, rootState } ): Promise<void> {
    //     const queryBuilder = new AnalyticsApiQueryBuilder({
    //         dateContext: rootState.dateContext,
    //         segments: rootState.selectedSegments,
    //         dimensions: ['te.eventCategory'],
    //         metrics: ['te.eventOccupancyRate'],
    //     });
    //
    //     const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-overview-occupancy-per-event-category' );
    //     commit('setOverviewOccupancyPerEventCategoryData', data);
    // },

    async fetchDetailKpiData({ commit, rootState, state }, {eventId}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            // dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [],
            metrics: ['te.sold', 'te.revenue', 'te.reserved', 'te.abandoned', 'te.visitedPeriods', 'te.groupSize', 'te.eventOccupancyRate', 'te.salesVelocityDays', 'te.eventCapacity'],
            // filters: [{eventId: {equals: eventId}}],
            filters: [{key: 'te.eventId', operator: 'equals', value: eventId}],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-detail-kpi' );
        commit('setDetailKpiData', data);
    },

    async fetchDetailTimeSeriesData({ commit, rootState }, {timeDimension, metric, eventId} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            // dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [timeDimension],
            metrics: [metric],
            // filters: [{eventId: {equals: eventId}}],
            filters: [{key: 'te.eventId', operator: 'equals', value: eventId}],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-detail-time-series' );
        commit('setDetailTimeSeriesData', data);
    },

    async fetchDetailNewVsReturningData({ commit, rootState }, {eventId} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            // dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.customerType'],
            metrics: ['te.uniqueCustomers'],
            // filters: [{eventId: {equals: eventId}}],
            filters: [{key: 'te.eventId', operator: 'equals', value: eventId}],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-detail-new-vs-returning' );
        commit('setDetailNewVsReturningData', data);
    },

    async fetchDetailPriceTypesData({ commit, rootState }, {eventId} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            // dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.priceType'],
            metrics: ['te.sold'],
            // filters: [{eventId: {equals: eventId}}],
            filters: [{key: 'te.eventId', operator: 'equals', value: eventId}],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-detail-price-type' );
        commit('setDetailPriceTypesData', data);
    },

    async fetchDetailPriceTypesTableData({ commit, rootState }, {eventId} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            // dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.priceType'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue', 'te.revenueExTax'],
            // filters: [{eventId: {equals: eventId}}],
            filters: [{key: 'te.eventId', operator: 'equals', value: eventId}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-detail-price-type-table' );
        commit('setDetailPriceTypesTableData', data);
    },

    async fetchDetailCustomersData({ commit, rootState }, {eventId} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            // dateContext: rootState.dateContext,
            // segments: rootState.selectedSegments,
            dimensions: ['te.priceType', 'te.customer', 'te.transactionDateHourMinute'],
            metrics: ['te.sold', 'te.revenue'],
            filters: [{key: 'te.eventId', operator: 'equals', value: eventId}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-detail-customers' );
        commit('setDetailCustomersData', data);
    },

    // async fetchListEventsData({ commit, rootState } ): Promise<void> {
    async fetchListEventsData({ commit, rootState }, {eventStartDate} ): Promise<void> {
        const filter = eventStartDate ? [{key: 'te.eventStartDate', operator: 'equals', value: eventStartDate}] : undefined;
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.eventOutline', 'te.eventId'],
            // dimensions: ['te.eventId', 'te.eventTitle', 'te.eventLocation', 'te.eventStartDate'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue', 'te.salesVelocityDays', 'te.eventOccupancyRate', 'te.uniqueCustomers', 'te.eventCapacity'],
            filters: filter,
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-list' );
        commit('setListEventsData', data);
    },


    async fetchOccupancyTimeSeriesData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            // segments: rootState.selectedSegments,
            dimensions: ['te.eventStartDayOfWeek'],
            metrics: ['te.eventOccupancyRate'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-occupancy-time-series' );
        commit('setOccupancyTimeSeriesData', data);
    },

    async fetchOccupancyPerEventCategoryData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            // segments: rootState.selectedSegments,
            dimensions: ['te.eventCategory'],
            metrics: ['te.eventOccupancyRate'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-occupancy-per-event-category' );
        commit('setOccupancyPerEventCategoryData', data);
    },

    async fetchOccupancyPerTimeData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            // segments: rootState.selectedSegments,
            dimensions: ['te.eventStartDayOfWeek', 'te.eventStartHour'],
            metrics: ['te.eventOccupancyRate'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-occupancy-per-time' );
        commit('setOccupancyPerTimeData', data);
    },


    async fetchVisitTimeData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.eventStartDayOfWeek', 'te.eventStartHour'],
            metrics: ['te.sold'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-visit-time' );
        commit('setVisitTimeData', data);
    },


    async fetchEventCategoriesData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.eventCategory'],
            metrics: ['te.sold', 'te.revenue', 'te.salesVelocityDays', 'te.eventOccupancyRate', 'te.uniqueCustomers', 'te.eventCapacity'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-category' );
        commit('setEventCategoriesData', data);
    },


    async fetchEventPriceTypeData({ commit, rootState }, {eventStartDate}): Promise<void> {
        const filter = eventStartDate ? [{key: 'te.eventStartDate', operator: 'equals', value: eventStartDate}] : undefined;
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.priceType', 'te.eventTitle', 'te.eventLocation', 'te.eventStartDateHourMinute', 'te.eventId'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue'],
            filters: filter,
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-price-type' );
        commit('setEventPriceTypesData', data);
    },


};
