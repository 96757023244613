import {GetterTree} from 'vuex';
import {EventState} from './models';
import {RootState} from '@/store/models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {MapType, SeriesBuilder, ValueMap, ValueType} from '@/util/SeriesBuilder';
import {SeriesData} from '@ticketengine/chart';

export const getters: GetterTree<EventState, RootState> = {

    overviewKpiData(state): AnalyticsApiQueryResponse|null {
        return state.overview.kpi;
    },

    overviewTimeSeriesData(state): AnalyticsApiQueryResponse|null {
        return state.overview.timeSeries;
    },

    overviewTopEventsData(state): AnalyticsApiQueryResponse|null {
        return state.overview.topEvents;
    },

    overviewSalesPerEventCategoryData(state): AnalyticsApiQueryResponse|null {
        return state.overview.salesPerEventCategory;
    },

    overviewOccupancyPerEventCategoryData(state): AnalyticsApiQueryResponse|null {
        return state.overview.occupancyPerEventCategory;
    },

    listEventData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.list.events;
    },

    detailKpiData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.detail.kpi;
    },

    detailTimeSeriesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.detail.timeSeries;
    },

    detailNewVsReturningData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.detail.newVsReturning;
    },

    detailPriceTypesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.detail.priceTypes;
    },

    detailPriceTypesTableData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.detail.priceTypesTable;
    },

    detailCustomersData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.detail.customers;
    },


    occupancyTimeSeriesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.occupancy.timeSeries;
    },
    occupancyPerEventCategoryData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.occupancy.perEventCategory;
    },
    occupancyPerTimeData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.occupancy.perTime;
    },


    visitTimeData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.visitTime;
    },


    eventCategoriesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.eventCategory.eventCategories;
    },


    eventPriceTypesData(state): AnalyticsApiQueryResponse|null {
        return state.eventPriceTypes.priceTypes;
    },

};
