import {ActionTree} from 'vuex';
import router from '@/router';
import {RootState} from '@/store/models';
import {SegmentSummery, SegmentType} from '@/models/segment';
import {DateContextPeriod, DateContextTransaction, DateContextType, Period} from '@/models/date-context';
import analyticsApi from '@/services/AnalyticsApiService';
import {dateRangePresets} from '@/config/date-range-presets';



export const actions: ActionTree<RootState, RootState> = {
    startJob({ commit, state }, {job}): void {
        commit('startJob', job);
    },

    endJob({ commit, state }, {jobId}): void {
        commit('endJob', jobId);
    },

    async fetchSegmentSummaries({ commit, state }): Promise<void> {
        const response = await analyticsApi.getSegments();
        commit('setSegmentSummaries', response.data);
        commit('setSelectedSegments', [response.data[0]]);
    },

    async fetchPeriods({ commit, state }): Promise<void> {
        const response = await analyticsApi.getPeriods();
        commit('setPeriods', response.data);
    },

    fetchDateContext({ commit, state }): void {
        // set default date context as period
        // if (!state.dateContext && state.periods && state.periods.length > 0) {
        //     const dateContext: DateContextPeriod = {
        //         type: DateContextType.period,
        //         // periodId: '1',
        //         periodId: state.periods[0].id,
        //         periodName: state.periods[0].name,
        //         compareTo: [],
        //     };
        //     commit('setDateContext', dateContext);
        // }

        // set default date context as transaction last 30 days
        if (!state.dateContext && state.dateRangePresets && state.dateRangePresets.length > 0) {
            const dateContext: DateContextTransaction = {
                type: DateContextType.transaction,
                range: state.dateRangePresets[0].getDateRange(),
                rangePreset: state.dateRangePresets[0],
                compareTo: [],
            };
            commit('setDateContext', dateContext);
        }
    },

    setEditMode({ commit }, inEditMode): void {
        commit('setEditMode', inEditMode);
    },

    setDateContext({ commit }, {dateContext}): void {
        commit('setDateContext', dateContext);
    },

    selectSegments({ commit }, {segments}): void {
        commit('setSelectedSegments', segments);
    },

    async fetchReports({ commit, state }): Promise<void> {
        const response = await analyticsApi.getReports();
        commit('setReports', response.data);
    },

    async login({ commit, state }, {username, password}): Promise<void> {
        // try {
            await analyticsApi.getToken(username, password);
            router.push({name: 'dashboard'});
//         } catch (e) {
// console.error(e);
//         }
    },
};
