import Vue from 'vue';
import Vuex from 'vuex';
import { RootState } from './models';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
// import { core } from '@/modules/core/store';
import { audience } from '@/modules/audience/store';
import { behaviour } from '@/modules/behaviour/store';
import { dashboard } from '@/modules/dashboard/store';
import { event } from '@/modules/event/store';
import { financial } from '@/modules/financial/store';
import { report } from '@/modules/report/store';
import { segment } from '@/modules/segment/store';
import {navigation} from '@/config/navigation';
import {factOptions, groupOperators} from '@/config/condition';
import {dateRangePresets} from '@/config/date-range-presets';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: '1.0.0',
    jobs: [],
    inEditMode: true,
    navigation,
    factOptions,
    groupOperators,
    segments: [],
    selectedSegments: [],
    dateRangePresets,
    periods: [],
    dateContext: undefined,
    defaultCurrency: {name: 'Euro', code: 'EUR', symbol: '€', exponent: 2},
    reports: [],
  },
  getters,
  actions,
  mutations,
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    // core,
    audience,
    behaviour,
    dashboard,
    event,
    financial,
    report,
    segment,
  },
});
