import {GetterTree} from 'vuex';
import {ReportState} from './models';
import {RootState} from '@/store/models';
import {AnalyticsApiQueryResponse, Filter} from '@/models/analytics-api';
import {ReportChartData, ReportFilter, ReportRow} from '@/models/report';


export const getters: GetterTree<ReportState, RootState> = {

    id(state): string {
        return state.id;
    },

    title(state): string {
        return state.title;
    },

    rows(state): ReportRow[] {
        return state.rows;
    },

    filters(state): ReportFilter[] {
        return state.filters;
    },

    chartData(state): ReportChartData[] {
        return state.chartData;
    },

    isNew(state): boolean {
        return state.id === 'new';
    },

};
