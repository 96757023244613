import {Module} from 'vuex';
import {RootState} from '@/store/models';
import {getters} from '@/modules/dashboard/store/getters';
import {actions} from '@/modules/dashboard/store/actions';
import {mutations} from '@/modules/dashboard/store/mutations';
import {DashboardState} from '@/modules/dashboard/store/models';


export const state: DashboardState = {
    kpi: null,
    timeSeries: null,
    topEvents: null,
    salesChannel: null,
};

const namespaced: boolean = true;

export const dashboard: Module<DashboardState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

