import {Module} from 'vuex';
import {RootState} from '@/store/models';
import {ReportState} from '@/modules/report/store/models';
import {getters} from '@/modules/report/store/getters';
import {actions} from '@/modules/report/store/actions';
import {mutations} from '@/modules/report/store/mutations';
import {DataSourceType, TE_DATASOURCE_NAME} from '@/models/report';


export const state: ReportState = {
    id: 'new',
    title: 'My report',
    rows: [],
    filters: [{filters: [], dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: TE_DATASOURCE_NAME.orderLines}}],
    chartData: [],
};

const namespaced: boolean = true;

export const report: Module<ReportState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

