import {ActionTree} from 'vuex';
import {SegmentState} from './models';
import {RootState} from '@/store/models';
import analyticsApi from '@/services/AnalyticsApiService';
import router from '@/router';

import {
    IntegrationNetwork,
    IntegrationSyncStatus,
    Segment,
    SegmentSummery,
    SegmentType,
} from '@/models/segment';


export const actions: ActionTree<SegmentState, RootState> = {
    async fetchSegments({ commit }, {filters}: any): Promise<void> {
        const response = await analyticsApi.getSegments();
        commit('setSegments', response.data);
    },

    async fetchSegment({ commit }, {id}): Promise<void> {
        const response = await analyticsApi.getSegment(id);
        commit('setSegment', response.data);
    },

    async createSegment({ commit }, {segment}): Promise<void> {
        const response = await analyticsApi.createSegment(segment);
        commit('setSegment', segment);
    },

    async editSegment({ commit }, {segment}): Promise<void> {
        const response = await analyticsApi.editSegment(segment);
        commit('setSegment', segment);
    },

    async removeSegment({ commit }, {id}): Promise<void> {
        const response = await analyticsApi.removeSegment(id);
        commit('clearSegment');
    },

    async clearSegment({ commit }): Promise<void> {
        commit('clearSegment');
    },



    async fetchSegmentIntegrations({ commit }, {segmentId}: any): Promise<void> {
        const response = await analyticsApi.getSegmentIntegrations(segmentId);
        commit('setSegmentIntegrations', response.data);
    },

    async createSegmentIntegration({ commit, dispatch }, {segmentId, integrationConfigId, integrationName, segmentConfig}): Promise<void> {
        const response = await analyticsApi.createSegmentIntegration(segmentId, integrationConfigId, integrationName, segmentConfig);
        dispatch('fetchSegmentIntegrations', {segmentId});
    },

    async removeSegmentIntegration({ commit, dispatch }, {segmentId, integrationConfigId}): Promise<void> {
        const response = await analyticsApi.removeSegmentIntegration(segmentId, integrationConfigId);
        dispatch('fetchSegmentIntegrations', {segmentId});
        // router.push({name: 'login'});
    },



    async fetchIntegrationConfigs({ commit }): Promise<void> {
        const response = await analyticsApi.getIntegrationConfigs();
        commit('setIntegrationConfigs', response.data);
    },

    async fetchMailchimpLists({ commit }, {integrationConfigId}): Promise<void> {
        const response = await analyticsApi.getIntegrationConfigMailchimpListOptions(integrationConfigId);
        commit('setMailchimpLists', response.data);
    },

};
