import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Locales } from './locales';
import en from './en.json';
import nl from './nl.json';

export const messages = {
    [Locales.EN]: en,
    [Locales.NL]: nl,
};

export const defaultLocale = Locales.EN;



Vue.use(VueI18n);

//
export default new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});
