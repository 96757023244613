import {ActionTree} from 'vuex';
import {ReportState} from './models';
import {RootState} from '@/store/models';
import analyticsApi from '@/services/AnalyticsApiService';
import {AnalyticsApiQueryBuilder} from '@/util/AnalyticsApiQueryBuilder';
import {DataSourceType, TE_DATASOURCE_NAME} from '@/models/report';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import router from '@/router';

export const actions: ActionTree<ReportState, RootState> = {

    // async fetchReportData({ commit, rootState }): Promise<void> {
    //
    // },

    async updateTitle({commit, rootState}, {title}): Promise<void> {
        commit('setTitle', title);
    },

    async updateRows({commit, rootState}, {rows}): Promise<void> {
        commit('setRows', rows);
    },

    async updateFilters({commit, rootState}, {filters}): Promise<void> {
        commit('setFilters', filters);
    },

    async fetchReport({commit, dispatch}, {id}): Promise<void> {
        const response = await analyticsApi.getReport(id);
        commit('setId', response.data.id);
        commit('setTitle', response.data.title);
        commit('setRows', response.data.rows);
        dispatch('fetchReportData');
    },

    async newReport({commit}): Promise<void> {
        commit('setId', 'new');
        commit('setTitle', 'My report');
        commit('setRows', []);
        commit('setFilters', [{filters: [], dataSource: {type: DataSourceType.TicketEngineAnalyticsApi, name: TE_DATASOURCE_NAME.orderLines}}]);
        commit('setData', []);
    },

    async saveReport({state}): Promise<void> {
        if (state.id === 'new') {
            await analyticsApi.createReport({title: state.title, rows: state.rows});
        } else {
            await analyticsApi.editReport({id: state.id, title: state.title, rows: state.rows});
        }
    },

    async removeReport({state, dispatch}, {id}): Promise<void> {
        await analyticsApi.removeReport(id);
        dispatch('newReport');
        await router.push({name: 'report'});
    },

    async fetchReportData({commit, state, rootState, dispatch}): Promise<void> {
        const charts = state.rows.flatMap((row) => row.columns.flatMap((c) => c.charts));
        charts.forEach((chart) => {
            dispatch('fetchChartData', {chart});
        });
    },

    async fetchChartData({commit, state, rootState}, {chart}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: chart.useDateContext ? rootState.dateContext : undefined,
            segments: chart.useSegments ? rootState.selectedSegments : undefined,
            filters: chart.useReportFilters && state.filters.length > 0 && state.filters[0].filters.length > 0 ? state.filters[0].filters : undefined,
            includeEmptyRows: chart.includeEmptyRows ?? false,
            dimensions: chart.dimensions,
            metrics: chart.metrics,
            // filters: [{key: 'te.eventOutline', operator: 'notEquals', value: null}],
        });

        if (chart.dataSource.type === DataSourceType.TicketEngineAnalyticsApi && chart.dataSource.name === TE_DATASOURCE_NAME.orderLines) {
            const data = await analyticsApi.reportOrderLine(queryBuilder.build(), chart.id);
            commit('addData', {chartId: chart.id, data});
        }
        if (chart.dataSource.type === DataSourceType.TicketEngineAnalyticsApi && chart.dataSource.name === TE_DATASOURCE_NAME.payments) {
            const data = await analyticsApi.reportPayment(queryBuilder.build(), chart.id);
            commit('addData', {chartId: chart.id, data});
        }
    },

    getChartData({commit, state, rootState}, {chartId}): AnalyticsApiQueryResponse|null {
        const s = state.chartData.find((cd) => cd.chartId === chartId);
        if (s) {
            return s.data;
        }
        return null;
    },

};
