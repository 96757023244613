import { MutationTree } from 'vuex';
import { AudienceState } from './models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const mutations: MutationTree<AudienceState> = {
    // profileLoaded(state, payload: User) {
    //     state.error = false;
    //     state.user = payload;
    // },
    // profileError(state) {
    //     state.error = true;
    //     state.user = undefined;
    // }

    setAgeData(state, data: AnalyticsApiQueryResponse) {
        state.age = data;
    },
    setGenderData(state, data: AnalyticsApiQueryResponse) {
        state.gender = data;
    },
    setGeoData(state, data: AnalyticsApiQueryResponse) {
        state.geo = data;
    },

};
