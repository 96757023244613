import { MutationTree } from 'vuex';
import { SegmentState } from './models';
import {IntegrationConfig, Segment, SegmentIntegration, SegmentSummery} from '@/models/segment';
import {MailchimpList} from '@/models/mailchimp';

export const mutations: MutationTree<SegmentState> = {
    // profileLoaded(state, payload: User) {
    //     state.error = false;
    //     state.user = payload;
    // },
    // profileError(state) {
    //     state.error = true;
    //     state.user = undefined;
    // }
    setSegments(state, segments: SegmentSummery[]) {
        state.segments = segments;
    },
    setSegment(state, segment: Segment) {
        state.segment = segment;
    },
    clearSegment(state) {
        state.segment = null;
    },

    setSegmentIntegrations(state, integrations: SegmentIntegration[]) {
        state.integrations = integrations;
    },

    setIntegrationConfigs(state, integrationConfigs: IntegrationConfig[]) {
        state.integrationConfigs = integrationConfigs;
    },

    setMailchimpLists(state, mailchimpLists: MailchimpList[]) {
        state.mailchimpLists = mailchimpLists;
    },
};
