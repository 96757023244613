import {TranslateResult} from 'vue-i18n';
import {AnalyticsApiQueryResponse, Filter} from '@/models/analytics-api';


export interface Report {
    id?: string;
    title: string;
    rows: ReportRow[];
}

export interface ReportRow {
    id: string;
    columns: ReportColumn[];
}


export interface ReportColumn {
    id: string;
    charts: ReportChart[];
}


export interface ReportChart {
    id: string;
    type: ReportChartType;
    dataSource: DataSource;
    dimensions: string[];
    metrics: string[];
    // filters: string[];
    useSegments: boolean;
    useDateContext: boolean;
    useReportFilters: boolean;
    includeEmptyRows: boolean;
    title: string;
}

export enum ReportChartType {
    line = 'line',
    bar = 'bar',
    column = 'column',
    pie = 'pie',
    table = 'table',
    area = 'area',
    scatter = 'scatter',
    bubble = 'bubble',
    treemap = 'treemap',
}

export interface ReportChartData {
    chartId: string;
    data: AnalyticsApiQueryResponse;
}

export interface ReportFilter {
    filters: Filter[];
    dataSource: DataSource;
}

export interface DataSource {
    type: DataSourceType;
    name: string;
}

export enum DataSourceType {
    TicketEngineAnalyticsApi = 0,
}

export const TE_DATASOURCE_NAME = {
    orderLines: 'OrderLines',
    payments: 'Payments',
};

export interface PropertyOption {
    id: string;
    label: string | TranslateResult;
    description: string | TranslateResult;
}

export interface DataSourceSchema {
    dimensions: DataSourceSchemaDimensionEntry[];
    metrics: DataSourceSchemaMetricEntry[];
}

export interface DataSourceSchemaEntry {
    name: string;
    field: string;
    dataType: string;
    // dateUnit?: string;
    // aggregateFunction?: string;
}

export interface DataSourceSchemaMetricEntry extends DataSourceSchemaEntry {
    aggregateFunction: string;
}

export interface DataSourceSchemaDimensionEntry extends DataSourceSchemaEntry {
    dateUnit?: string;
}

