import {Module} from 'vuex';
import {RootState} from '@/store/models';
import {EventState} from '@/modules/event/store/models';
import {getters} from '@/modules/event/store/getters';
import {actions} from '@/modules/event/store/actions';
import {mutations} from '@/modules/event/store/mutations';


export const state: EventState = {
    overview: {
        kpi: null,
        timeSeries: null,
        topEvents: null,
        salesPerEventCategory: null,
        occupancyPerEventCategory: null,
    },
    list: {
        events: null,
    },
    detail: {
        kpi: null,
        timeSeries: null,
        newVsReturning: null,
        priceTypes: null,
        priceTypesTable: null,
        customers: null,
    },
    occupancy: {
        timeSeries: null,
        perEventCategory: null,
        perTime: null,
    },
    visitTime: null,
    eventCategory: {
        eventCategories: null,
    },
    eventPriceTypes: {
        priceTypes: null,
    },
};

const namespaced: boolean = true;

export const event: Module<EventState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

