import { MutationTree } from 'vuex';
import { BehaviourState } from './models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const mutations: MutationTree<BehaviourState> = {
    // profileLoaded(state, payload: User) {
    //     state.error = false;
    //     state.user = payload;
    // },
    // profileError(state) {
    //     state.error = true;
    //     state.user = undefined;
    // }


    // setKpiData(state, data: AnalyticsApiQueryResponse) {
    //     state.kpi = data;
    // },
    //
    // setTimeSeriesData(state, data: AnalyticsApiQueryResponse) {
    //     state.timeSeries = data;
    // },

    setOverviewKpiData(state, data: AnalyticsApiQueryResponse) {
        state.overview.kpi = data;
    },

    setOverviewTimeSeriesData(state, data: AnalyticsApiQueryResponse) {
        state.overview.timeSeries = data;
    },

    setOverviewSalesChannelData(state, data: AnalyticsApiQueryResponse) {
        state.overview.salesChannel = data;
    },

    setOverviewRegisterData(state, data: AnalyticsApiQueryResponse) {
        state.overview.register = data;
    },

    setOverviewPriceTypesData(state, data: AnalyticsApiQueryResponse) {
        state.overview.priceType = data;
    },



    setSalesChannelData(state, data: AnalyticsApiQueryResponse) {
        state.salesChannel = data;
    },

    setSalesChannelLedgerData(state, data: AnalyticsApiQueryResponse) {
        state.salesChannelLedger = data;
    },

    setRegisterData(state, data: AnalyticsApiQueryResponse) {
        state.register = data;
    },

    setRegisterLedgerData(state, data: AnalyticsApiQueryResponse) {
        state.registerLedger = data;
    },

    setPurchaseTimeData(state, data: AnalyticsApiQueryResponse) {
        state.purchaseTime = data;
    },

    setPriceTypesData(state, data: AnalyticsApiQueryResponse) {
        state.priceType = data;
    },



    setPaymentTimeSeriesData(state, data: AnalyticsApiQueryResponse) {
        state.paymentTimeSeries = data;
    },

    setPaymentMethodData(state, data: AnalyticsApiQueryResponse) {
        state.paymentMethod = data;
    },

    setPaymentServiceProviderData(state, data: AnalyticsApiQueryResponse) {
        state.paymentServiceProvider = data;
    },

    setPaymentMethodDetailData(state, data: AnalyticsApiQueryResponse) {
        state.paymentMethodDetail = data;
    },

    setPaymentServiceProviderDetailData(state, data: AnalyticsApiQueryResponse) {
        state.paymentServiceProviderDetail = data;
    },

};
