import {Module} from 'vuex';
import {RootState} from '@/store/models';
import {FinancialState} from '@/modules/financial/store/models';
import {getters} from '@/modules/financial/store/getters';
import {actions} from '@/modules/financial/store/actions';
import {mutations} from '@/modules/financial/store/mutations';


export const state: FinancialState = {
    kpi: null,
    timeSeries: null,
    salesChannel: null,
    salesChannelLedger: null,
    register: null,
    registerLedger: null,
    priceType: null,
    eventPriceTypes: null,
    paymentTimeSeries: null,
    paymentMethod: null,
    paymentServiceProvider: null,
    paymentMethodDetail: null,
    paymentServiceProviderDetail: null,
};

const namespaced: boolean = true;

export const financial: Module<FinancialState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

