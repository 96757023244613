import {GetterTree} from 'vuex';
import {FinancialState} from './models';
import {RootState} from '@/store/models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const getters: GetterTree<FinancialState, RootState> = {

    salesChannelData(state): AnalyticsApiQueryResponse | null {
        return state.salesChannel;
    },

    salesChannelLedgerData(state): AnalyticsApiQueryResponse | null {
        return state.salesChannelLedger;
    },

    registerData(state): AnalyticsApiQueryResponse | null {
        return state.register;
    },

    registerLedgerData(state): AnalyticsApiQueryResponse | null {
        return state.registerLedger;
    },

    priceTypesData(state): AnalyticsApiQueryResponse | null {
        return state.priceType;
    },

    eventPriceTypesData(state): AnalyticsApiQueryResponse | null {
        return state.eventPriceTypes;
    },

    paymentTimeSeriesData(state): AnalyticsApiQueryResponse | null {
        return state.paymentTimeSeries;
    },

    paymentMethodData(state): AnalyticsApiQueryResponse | null {
        return state.paymentMethod;
    },

    paymentServiceProviderData(state): AnalyticsApiQueryResponse | null {
        return state.paymentServiceProvider;
    },

    paymentMethodDetailData(state): AnalyticsApiQueryResponse | null {
        return state.paymentMethodDetail;
    },

    paymentServiceProviderDetailData(state): AnalyticsApiQueryResponse | null {
        return state.paymentServiceProviderDetail;
    },

};
