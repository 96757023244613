
import { Component, Vue } from 'vue-property-decorator';
// import HelloWorld from './components/HelloWorld.vue';
// import AudienceOverview from '@/modules/audience/views/AudienceOverview';

@Component({
  components: {
    // HelloWorld,
    // AudienceOverview,
  },
})
export default class App extends Vue {}
