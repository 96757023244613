import {NavigationGroup} from '@/models/navigation';
// import {i18n} from '@/main';
import i18n from '@/i18n/index';

export const navigation: NavigationGroup[] = [
    {
        name: '1',
        items: [
            {
                name: 'dashboard',
                label: i18n.t('dashboard.label'),
                icon: 'dashboard',
            },
            {
                name: 'event',
                label: i18n.t('event.label'),
                icon: 'event',
                items: [
                    {
                        name: 'event-overview',
                        label: i18n.t('common.overview'),
                        icon: 'event',
                    },
                    // {
                    //     name: 'event-occupancy',
                    //     label: i18n.t('event.occupancy'),
                    //     icon: 'event',
                    // },
                    {
                        name: 'event-visit-time',
                        label: i18n.t('event.visitTime'),
                        icon: 'event',
                    },
                    // {
                    //     name: 'event-category',
                    //     label: i18n.t('event.category'),
                    //     icon: 'event',
                    // },
                    // {
                    //     name: 'event-list',
                    //     label: i18n.t('common.detail'),
                    //     icon: 'event',
                    // },
                    // {
                    //     name: 'event-price-type',
                    //     label: i18n.t('event.priceTypes'),
                    //     icon: 'event',
                    // },
                ],
            },
            // {
            //     name: 'audience',
            //     label: i18n.t('audience.label'),
            //     icon: 'audience',
            // },
            // {
            //     name: 'behaviour',
            //     label: i18n.t('behaviour.label'),
            //     icon: 'behaviour',
            //     items: [
            //         {
            //             name: 'behaviour-overview',
            //             label: i18n.t('common.overview'),
            //             icon: 'behaviour',
            //         },
            //         // {
            //         //     name: 'behaviour-sales-funnel',
            //         //     label: i18n.t('behaviour.salesFunnel'),
            //         //     icon: 'behaviour',
            //         // },
            //         {
            //             name: 'behaviour-price-type',
            //             label: i18n.t('behaviour.priceType'),
            //             icon: 'behaviour',
            //         },
            //         {
            //             name: 'behaviour-payment',
            //             label: i18n.t('behaviour.payment'),
            //             icon: 'behaviour',
            //         },
            //         {
            //             name: 'behaviour-sales-channel',
            //             label: i18n.t('behaviour.salesChannel'),
            //             icon: 'behaviour',
            //         },
            //         {
            //             name: 'behaviour-register',
            //             label: i18n.t('behaviour.register'),
            //             icon: 'behaviour',
            //         },
            //         {
            //             name: 'behaviour-purchase-time',
            //             label: i18n.t('behaviour.purchaseTime'),
            //             icon: 'behaviour',
            //         },
            //     ],
            // },


            {
                name: 'financial',
                label: i18n.t('financial.label'),
                icon: 'financial',
                items: [
                    {
                        name: 'financial-payment',
                        label: i18n.t('financial.payment'),
                        icon: 'financial',
                    },
                    {
                        name: 'financial-sales-channel',
                        label: i18n.t('financial.salesChannel'),
                        icon: 'financial',
                    },
                    {
                        name: 'financial-register',
                        label: i18n.t('financial.register'),
                        icon: 'financial',
                    },
                    {
                        name: 'financial-price-type',
                        label: i18n.t('financial.priceType'),
                        icon: 'financial',
                    },
                    {
                        name: 'financial-event-price-type',
                        label: i18n.t('financial.eventPriceType'),
                        icon: 'financial',
                    },
                ],
            },

            // {
            //     name: 'test',
            //     label: 'test view',
            //     icon: 'audience',
            // },
        ],
    },
    {
        name: '3',
        items: [
            {
                name: 'report',
                label: i18n.t('report.label'),
                icon: 'custom-report',
            },
        ],
    },
    {
        name: '2',
        items: [
            {
                name: 'segment',
                label: i18n.t('segment.label'),
                icon: 'segment',
            },
        ],
    },
];
