import {ActionTree} from 'vuex';
import {DashboardState} from './models';
import {RootState} from '@/store/models';
import analyticsApi from '@/services/AnalyticsApiService';
import {AnalyticsApiQueryBuilder} from '@/util/AnalyticsApiQueryBuilder';


export const actions: ActionTree<DashboardState, RootState> = {

    async fetchKpi({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [],
            metrics: ['te.uniqueCustomers', 'te.sold', 'te.revenue', 'te.salesVelocityDays', 'te.visitedEvents', 'te.visitedPeriods', 'te.abandoned', 'te.groupSize', 'te.transactionValue'],
        });
        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-kpi');
        commit('setKpi', data);
    },

    async fetchTimeSeries({commit, rootState}, {timeDimension, metric}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [timeDimension],
            metrics: [metric],
        });
        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-time-series');
        commit('setTimeSeries', data);
    },

    async fetchTopEvents({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.eventTitle', 'te.eventLocation', 'te.eventStartDateHourMinute'],
            metrics: ['te.eventSold', 'te.eventCapacity', 'te.eventOccupancyRate', 'te.eventRevenue', 'te.sold'],
            // filters: [{key: 'te.paymentServiceProvider', operator: 'equals', value: psp}],
            includeEmptyRows: true,
            sort: [{field: 'te.sold', direction: 'desc'}],
            maxResults: 5,
        });
        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-top-event');
        commit('setTopEvents', data);
    },

    async fetchSalesChannel({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.salesChannel'],
            metrics: ['te.sold'],
            // filters: [{key: 'te.paymentServiceProvider', operator: 'equals', value: psp}],
            includeEmptyRows: false,
            sort: [{field: 'te.sold', direction: 'desc'}],
            // maxResults: 5,
        });
        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-sales-channel');
        commit('setSalesChannel', data);
    },

};
