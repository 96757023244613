import {MutationTree} from 'vuex';
import {SegmentSummery} from '@/models/segment';
import {DateContext, DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from '@/models/date-context';
import {Job, RootState} from '@/store/models';
import {Report} from '@/models/report';

export const mutations: MutationTree<RootState> = {
    startJob(state, job: Job) {
        if (state.jobs.map((j) => j.id).indexOf(job.id) === -1) {
            state.jobs.push(job);
        }
    },

    endJob(state, jobId: string) {
        state.jobs = state.jobs.filter((job) => job.id !== jobId);
    },

    setEditMode(state, inEditMode: boolean) {
        state.inEditMode = inEditMode;
    },

    setSegmentSummaries(state, segments: SegmentSummery[]) {
        state.segments = segments;
    },

    setSelectedSegments(state, segments: SegmentSummery[]) {
        state.selectedSegments = segments.map((s) => s.id);
    },

    setDateContext(state, context: DateContextPeriod|DateContextEvent|DateContextTransaction) {
        state.dateContext = context;
    },

    setPeriods(state, periods: Period[]) {
        state.periods = periods;
    },

    setReports(state, reports: Report[]) {
        state.reports = reports;
    },
};
