import {GetterTree} from 'vuex';
import {BehaviourState} from './models';
import {RootState} from '@/store/models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {MapType, SeriesBuilder, ValueMap, ValueType} from '@/util/SeriesBuilder';

export const getters: GetterTree<BehaviourState, RootState> = {

    // kpiData(state): AnalyticsApiQueryResponse|null {
    //     // return state.kpi;
    //     return state.kpi;
    // },
    //
    // timeSeriesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
    //     return state.timeSeries;
    // },

    overviewKpiData(state): AnalyticsApiQueryResponse|null {
        // return state.kpi;
        return state.overview.kpi;
    },

    overviewTimeSeriesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.overview.timeSeries;
    },

    overviewSalesChannelData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.overview.salesChannel;
    },

    overviewRegisterData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.overview.register;
    },

    overviewPriceTypesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.overview.priceType;
    },




    salesChannelData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.salesChannel;
    },

    salesChannelLedgerData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.salesChannelLedger;
    },

    registerData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.register;
    },

    registerLedgerData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.registerLedger;
    },

    purchaseTimeData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.purchaseTime;
    },

    priceTypesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.priceType;
    },

    paymentTimeSeriesData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.paymentTimeSeries;
    },

    paymentMethodData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.paymentMethod;
    },

    paymentServiceProviderData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.paymentServiceProvider;
    },

    paymentMethodDetailData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.paymentMethodDetail;
    },

    paymentServiceProviderDetailData(state, getters, rootState): AnalyticsApiQueryResponse|null {
        return state.paymentServiceProviderDetail;
    },

    // lineChartSeries(state, getters, rootState): SeriesData[] {
    //     if (state.timeSeries) {
    //         const valueMapX: ValueMap = {mapType: MapType.dimension, valueType: ValueType.date, field: 'te.day'};
    //         const valueMapY: ValueMap = {mapType: MapType.metric, valueType: ValueType.int, field: 'te.sold'};
    //         const seriesBuilder = new SeriesBuilder(state.timeSeries, rootState.segments, valueMapX, valueMapY);
    //         return seriesBuilder.build();
    //     }
    //     return [];
    // },

};
