import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';
import EventOverview from '@/modules/event/views/EventOverview.vue';
import AudienceOverview from '@/modules/audience/views/AudienceOverview.vue';
import BehaviourOverview from '@/modules/behaviour/views/BehaviourOverview.vue';
import SegmentOverview from '@/modules/segment/views/SegmentOverview.vue';
import SegmentDetailView from '@/modules/segment/views/SegmentDetailView.vue';


Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue'),
  },




                          {
                            path: '/test',
                            name: 'test',
                            component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/TestView.vue'),
                          },


  // dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/dashboard/views/Dashboard.vue'),
  },


  // event
  {
    path: '/event',
    name: 'event',
    // component: EventOverview,
    redirect: { name: 'event-overview' },
  },
  {
    path: '/event/overview',
    name: 'event-overview',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/EventOverview.vue'),
  },
  {
    path: '/event/detail',
    name: 'event-list',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/EventListView.vue'),
  },
  {
    path: '/event/detail/:id',
    name: 'event-detail',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/EventDetailView.vue'),
  },
  {
    path: '/event/occupancy',
    name: 'event-occupancy',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/EventOccupancyView.vue'),
  },
  {
    path: '/event/visit-time',
    name: 'event-visit-time',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/VisitTimeView.vue'),
  },
  {
    path: '/event/category',
    name: 'event-category',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/EventCategoryView.vue'),
  },
  {
    path: '/event/price-type',
    name: 'event-price-type',
    component: () => import(/* webpackChunkName: "event" */ '@/modules/event/views/EventPriceTypeView.vue'),
  },


  // // audience
  // {
  //   path: '/audience',
  //   name: 'audience',
  //   component: AudienceOverview,
  // },
  //
  //
  // // behaviour
  // {
  //   path: '/behaviour',
  //   name: 'behaviour',
  //   // component: BehaviourOverview,
  //   redirect: { name: 'behaviour-overview' },
  // },
  // {
  //   path: '/behaviour/overview',
  //   name: 'behaviour-overview',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/BehaviourOverview.vue'),
  // },
  // {
  //   path: '/behaviour/funnel',
  //   name: 'behaviour-sales-funnel',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/SalesFunnelView.vue'),
  // },
  // {
  //   path: '/behaviour/purchase-time',
  //   name: 'behaviour-purchase-time',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/PurchaseTimeView.vue'),
  // },
  // {
  //   path: '/behaviour/price-type',
  //   name: 'behaviour-price-type',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/PriceTypeView.vue'),
  // },
  // {
  //   path: '/behaviour/sales-channel',
  //   name: 'behaviour-sales-channel',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/SalesChannelView.vue'),
  // },
  // {
  //   path: '/behaviour/sales-channel/:id/ledger',
  //   name: 'behaviour-sales-channel-ledger',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/SalesChannelLedgerView.vue'),
  // },
  // {
  //   path: '/behaviour/register',
  //   name: 'behaviour-register',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/RegisterView.vue'),
  // },
  // {
  //   path: '/behaviour/register/:id/ledger',
  //   name: 'behaviour-register-ledger',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/RegisterLedgerView.vue'),
  // },
  // {
  //   path: '/behaviour/payment',
  //   name: 'behaviour-payment',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/PaymentView.vue'),
  // },
  // {
  //   path: '/behaviour/payment/method/:id',
  //   name: 'behaviour-payment-method',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/PaymentMethodView.vue'),
  // },
  // {
  //   path: '/behaviour/payment/psp/:id',
  //   name: 'behaviour-payment-psp',
  //   component: () => import(/* webpackChunkName: "behaviour" */ '@/modules/behaviour/views/PaymentServiceProviderView.vue'),
  // },


  // financial
  {
    path: '/financial',
    name: 'financial',
    redirect: { name: 'financial-payment' },
  },
  {
    path: '/financial/payment',
    name: 'financial-payment',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/PaymentView.vue'),
  },
  {
    path: '/financial/payment/method/:id',
    name: 'financial-payment-method',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/PaymentMethodView.vue'),
  },
  {
    path: '/financial/payment/psp/:id',
    name: 'financial-payment-psp',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/PaymentServiceProviderView.vue'),
  },
  {
    path: '/financial/sales-channel',
    name: 'financial-sales-channel',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/SalesChannelView.vue'),
  },
  {
    path: '/financial/sales-channel/:id/ledger',
    name: 'financial-sales-channel-ledger',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/SalesChannelLedgerView.vue'),
  },
  {
    path: '/financial/register',
    name: 'financial-register',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/RegisterView.vue'),
  },
  {
    path: '/financial/register/:id/ledger',
    name: 'financial-register-ledger',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/RegisterLedgerView.vue'),
  },
  {
    path: '/financial/price-type',
    name: 'financial-price-type',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/PriceTypeView.vue'),
  },
  {
    path: '/financial/event-price-type',
    name: 'financial-event-price-type',
    component: () => import(/* webpackChunkName: "financial" */ '@/modules/financial/views/EventPriceTypeView.vue'),
  },



  // report
  {
    path: '/report',
    name: 'report',
    redirect: { name: 'report-list' },
  },
  {
    path: '/report',
    name: 'report-list',
    component: () => import(/* webpackChunkName: "custom-report" */ '@/modules/report/views/ReportListView.vue'),
  },
  {
    path: '/report/:id',
    name: 'report-detail',
    component: () => import(/* webpackChunkName: "custom-report" */ '@/modules/report/views/ReportView.vue'),
  },



  // segment
  {
    path: '/segment',
    name: 'segment',
    component: () => import(/* webpackChunkName: "segment" */ '@/modules/segment/views/SegmentOverview.vue'),
  },
  {
    path: '/segment/:id',
    name: 'segment-detail',
    component: () => import(/* webpackChunkName: "segment" */ '@/modules/segment/views/SegmentDetailView.vue'),
    children: [
      {
        path: 'detail',
        name: 'segment-detail-edit',
        components: {
          detail: () =>
              import(/* webpackChunkName: "segment" */ '@/modules/segment/components/SegmentDetailEdit.vue'),
        },
      },
      {
        path: 'integration',
        name: 'segment-detail-integration',
        components: {
          detail: () =>
              import(/* webpackChunkName: "segment" */ '@/modules/segment/components/SegmentDetailIntegration.vue'),
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
