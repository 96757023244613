import {MutationTree} from 'vuex';
import {DashboardState} from './models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const mutations: MutationTree<DashboardState> = {

    setKpi(state, data: AnalyticsApiQueryResponse) {
        state.kpi = data;
    },

    setTimeSeries(state, data: AnalyticsApiQueryResponse) {
        state.timeSeries = data;
    },

    setTopEvents(state, data: AnalyticsApiQueryResponse) {
        state.topEvents = data;
    },

    setSalesChannel(state, data: AnalyticsApiQueryResponse) {
        state.salesChannel = data;
    },

};
