import {GetterTree} from 'vuex';
import {DashboardState} from './models';
import {RootState} from '@/store/models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const getters: GetterTree<DashboardState, RootState> = {

    kpi(state): AnalyticsApiQueryResponse | null {
        return state.kpi;
    },

    timeSeries(state): AnalyticsApiQueryResponse | null {
        return state.timeSeries;
    },

    topEvents(state): AnalyticsApiQueryResponse | null {
        return state.topEvents;
    },

    salesChannel(state): AnalyticsApiQueryResponse | null {
        return state.salesChannel;
    },

};
