import { ActionTree } from 'vuex';
// import axios from 'axios';
import { BehaviourState } from './models';
import { RootState } from '@/store/models';
import analyticsApi from '@/services/AnalyticsApiService';
// import analyticsApi from '@/services/DummyAnalyticsApiService';
import {AnalyticsApiQueryBuilder} from '@/util/AnalyticsApiQueryBuilder';
// import {Period} from '@/models/date-context';


export const actions: ActionTree<BehaviourState, RootState> = {

    // async fetchKpiData({ commit, rootState, state }): Promise<void> {
    //     const queryBuilder = new AnalyticsApiQueryBuilder({
    //         dateContext: rootState.dateContext,
    //         segments: rootState.selectedSegments,
    //         dimensions: [],
    //         // metrics: ['te.uniqueCustomers', 'te.sold', 'te.revenue', 'te.salesVelocityDays', 'te.visitedEvents', 'te.visitedPeriods', 'te.groupSize', 'te.abandonedCarts', 'te.transactionValue', 'te.transactionsPerCustomer'],
    //         metrics: ['te.uniqueCustomers', 'te.sold', 'te.revenue', 'te.salesVelocityDays', 'te.visitedEvents', 'te.visitedPeriods', 'te.abandoned', 'te.groupSize'],
    //     });
    //
    //     const data = await analyticsApi.reportOrderLine( queryBuilder.build() );
    //     commit('setKpiData', data);
    // },
    //
    // async fetchTimeSeriesData({ commit, rootState }, {timeDimension, metric}): Promise<void> {
    //     const queryBuilder = new AnalyticsApiQueryBuilder({
    //         dateContext: rootState.dateContext,
    //         segments: rootState.selectedSegments,
    //         // paymentMethodDimensions: ['te.day'],
    //         dimensions: [timeDimension],
    //         metrics: [metric],
    //     });
    //
    //     const data = await analyticsApi.reportOrderLine( queryBuilder.build() );
    //     commit('setTimeSeriesData', data);
    // },

    async fetchOverviewKpiData({ commit, rootState, state }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [],
            // metrics: ['te.uniqueCustomers', 'te.sold', 'te.revenue', 'te.salesVelocityDays', 'te.visitedEvents', 'te.visitedPeriods', 'te.groupSize', 'te.abandonedCarts', 'te.transactionValue', 'te.transactionsPerCustomer'],
            metrics: ['te.uniqueCustomers', 'te.sold', 'te.revenue', 'te.salesVelocityDays', 'te.visitedEvents', 'te.visitedPeriods', 'te.abandoned', 'te.groupSize', 'te.transactionValue'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-overview-kpi' );
        commit('setOverviewKpiData', data);
    },

    async fetchOverviewTimeSeriesData({ commit, rootState }, {timeDimension, metric}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            // paymentMethodDimensions: ['te.day'],
            dimensions: [timeDimension],
            metrics: [metric],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-overview-time-series' );
        commit('setOverviewTimeSeriesData', data);
    },

    async fetchOverviewSalesChannelData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.salesChannel'],
            metrics: ['te.sold'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-overview-sales-channel' );
        commit('setOverviewSalesChannelData', data);
    },

    async fetchOverviewRegisterData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.register'],
            metrics: ['te.sold'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-overview-register' );
        commit('setOverviewRegisterData', data);
    },

    async fetchOverviewPriceTypesData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.priceType'],
            metrics: ['te.sold'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-overview-price-type' );
        commit('setOverviewPriceTypesData', data);
    },






    async fetchSalesChannelData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            // dimensions: ['te.salesChannel', 'te.salesChannelId'],
            dimensions: ['te.salesChannel'],
            metrics: ['te.sold', 'te.reserved', 'te.inCart', 'te.abandoned', 'te.revenue'],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-sales-channel' );
        commit('setSalesChannelData', data);
    },

    async fetchSalesChannelLedgerData({ commit, rootState }, {salesChannel} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            // segments: rootState.selectedSegments,
            dimensions: ['te.salesChannel', 'te.transactionId', 'te.customer', 'te.priceType', 'te.register'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue'],
            // filters: [{key: 'te.salesChannelId', operator: 'equals', value: salesChannelId}, {key: 'te.transactionLineStatus', operator: 'equals', value: 'completed'}],
            filters: [{key: 'te.salesChannel', operator: 'equals', value: salesChannel}, {key: 'te.transactionLineStatus', operator: 'equals', value: 'completed'}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-sales-channel-ledger' );
        commit('setSalesChannelLedgerData', data);
    },

    async fetchRegisterData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            // dimensions: ['te.register', 'te.registerId'],
            dimensions: ['te.register'],
            metrics: ['te.sold', 'te.reserved', 'te.inCart', 'te.abandoned', 'te.revenue'],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-register' );
        commit('setRegisterData', data);
    },

    async fetchRegisterLedgerData({ commit, rootState }, {register} ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            // segments: rootState.selectedSegments,
            // dimensions: ['te.register', 'te.registerId', 'te.customer', 'te.priceType'],
            dimensions: ['te.register', 'te.customer', 'te.priceType'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue'],
            // filters: [{key: 'te.registerId', operator: 'equals', value: registerId}, {key: 'te.transactionLineStatus', operator: 'equals', value: 'completed'}],
            filters: [{key: 'te.register', operator: 'equals', value: register}, {key: 'te.transactionLineStatus', operator: 'equals', value: 'completed'}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-register-ledger' );
        commit('setRegisterLedgerData', data);
    },

    async fetchPurchaseTimeData({ commit, rootState } ): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.transactionDayOfWeek', 'te.transactionHour'],
            metrics: ['te.sold'],
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-purchase-time' );
        commit('setPurchaseTimeData', data);
    },

    async fetchPriceTypesData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.priceType'],
            metrics: ['te.sold', 'te.reserved', 'te.inCart', 'te.abandoned', 'te.revenue'],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-behaviour-price-type' );
        commit('setPriceTypesData', data);
    },




    async fetchPaymentTimeSeriesData({ commit, rootState }, {timeDimension, metric}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [timeDimension],
            metrics: [metric],
        });

        const data = await analyticsApi.reportPayment( queryBuilder.build(), 'get-behaviour-payment-time-series' );
        commit('setPaymentTimeSeriesData', data);
    },

    async fetchPaymentMethodData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.paymentMethod'],
            metrics: ['te.payments', 'te.paymentRevenue'],
            includeEmptyRows: true,
        });

        const data = await analyticsApi.reportPayment( queryBuilder.build(), 'get-behaviour-payment-method' );
        commit('setPaymentMethodData', data);
    },

    async fetchPaymentServiceProviderData({ commit, rootState }): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.paymentServiceProvider'],
            metrics: ['te.payments', 'te.paymentRevenue'],
            includeEmptyRows: true,
        });

        const data = await analyticsApi.reportPayment( queryBuilder.build(), 'get-behaviour-payment-psp' );
        commit('setPaymentServiceProviderData', data);
    },

    async fetchPaymentMethodDetailData({ commit, rootState }, {paymentMethod}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            dimensions: ['te.paymentMethod', 'te.currencyCode', 'te.customer', 'te.transactionDateHourMinute'],
            metrics: ['te.paymentRevenue'],
            filters: [{key: 'te.paymentMethod', operator: 'equals', value: paymentMethod}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportPayment( queryBuilder.build(), 'get-behaviour-payment-method-detail' );
        commit('setPaymentMethodDetailData', data);
    },

    async fetchPaymentServiceProviderDetailData({ commit, rootState }, {psp}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            // dateContext: rootState.dateContext,
            // segments: rootState.selectedSegments,
            // dimensions: ['te.paymentServiceProvider'],
            // metrics: ['te.payments', 'te.paymentRevenue'],
            dateContext: rootState.dateContext,
            dimensions: ['te.paymentServiceProvider', 'te.currencyCode', 'te.customer', 'te.transactionDateHourMinute'],
            metrics: ['te.paymentRevenue'],
            filters: [{key: 'te.paymentServiceProvider', operator: 'equals', value: psp}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportPayment( queryBuilder.build(), 'get-behaviour-payment-psp-detail' );
        commit('setPaymentServiceProviderDetailData', data);
    },

};
