import { MutationTree } from 'vuex';
import { ReportState } from './models';
import {ReportChartData, ReportFilter, ReportRow} from '@/models/report';
import {Filter} from '@/models/analytics-api';

export const mutations: MutationTree<ReportState> = {

    setId(state, id: string): void {
        state.id = id;
    },

    setTitle(state, title: string): void {
        state.title = title;
    },

    setRows(state, rows: ReportRow[]): void {
        state.rows = rows;
    },

    setFilters(state, filters: ReportFilter[]): void {
        state.filters = filters;
    },

    setData(state, data: ReportChartData[]): void {
        state.chartData = data;
    },

    // addRow(state, row: ReportRow) {
    //     state.rows.push(row);
    // },



    addData(state, data: ReportChartData): void {
        state.chartData = state.chartData.filter((d) => d.chartId !== data.chartId);
        state.chartData.push(data);
    },

};
