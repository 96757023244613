import {TranslateResult} from 'vue-i18n';

// export enum DateRangePresets {
//     'lastWeek' = 'lastWeek',
//     'lastMonth' = 'lastMonth',
//     'last7Days' = 'last7Days',
//     'last30Days' = 'last30Days',
//     'thisYear' = 'thisYear',
//     'custom' = 'custom',
// }

export enum DateContextType {
    'period' = 'period',
    'event' = 'event',
    'transaction' = 'transaction',
}

export interface DateContext {
    type: DateContextType;
}

export interface DateContextPeriod extends DateContext {
    periodId: string;
    periodName: string;
    compareTo: string[];
}

export interface DateContextEvent extends DateContext {
    range: DateRange;
    rangePreset?: DateRangePreset;
    compareTo: DateRange[];
}

export interface DateContextTransaction extends DateContext {
    range: DateRange;
    rangePreset?: DateRangePreset;
    compareTo: DateRange[];
}

export function instanceOfDateContextPeriod(object: any): object is DateContextPeriod {
    return object.type && object.type === DateContextType.period;
}

export function instanceOfDateContextEvent(object: any): object is DateContextEvent {
    return object.type && object.type === DateContextType.event;
}

export function instanceOfDateContextTransaction(object: any): object is DateContextTransaction {
    return object.type && object.type === DateContextType.transaction;
}



export interface DateRangePreset {
    id: string;
    label: string|TranslateResult;
    getDateRange: (dateRange?: DateRange) => DateRange;
}

export interface Period {
    id: string;
    name: string;
    // start: Date;
    // end: Date;
}

export interface DateRange {
    min: Date;
    max: Date;
}

export interface DateRangeString {
    min: string;
    max: string;
}

export function instanceOfDateRange(object: any): object is DateRange {
    return object.min && object.max && object.min instanceof Date && object.max  instanceof Date;
    // return object && object.min && object.max;
}

export function instanceOfDateRangeString(object: any): object is DateRangeString {
    // return 'member' in object;
    return object.min && object.max && object.min instanceof String && object.max instanceof String;
}

