import { MutationTree } from 'vuex';
import { EventState } from './models';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';

export const mutations: MutationTree<EventState> = {

    setOverviewKpiData(state, data: AnalyticsApiQueryResponse) {
        state.overview.kpi = data;
    },

    setOverviewTimeSeriesData(state, data: AnalyticsApiQueryResponse) {
        state.overview.timeSeries = data;
    },

    setOverviewTopEventsData(state, data: AnalyticsApiQueryResponse) {
        state.overview.topEvents = data;
    },

    setOverviewSalesPerEventCategoryData(state, data: AnalyticsApiQueryResponse) {
        state.overview.salesPerEventCategory = data;
    },

    setOverviewOccupancyPerEventCategoryData(state, data: AnalyticsApiQueryResponse) {
        state.overview.occupancyPerEventCategory = data;
    },

    setListEventsData(state, data: AnalyticsApiQueryResponse) {
        state.list.events = data;
    },

    setDetailKpiData(state, data: AnalyticsApiQueryResponse) {
        state.detail.kpi = data;
    },

    setDetailTimeSeriesData(state, data: AnalyticsApiQueryResponse) {
        state.detail.timeSeries = data;
    },

    setDetailNewVsReturningData(state, data: AnalyticsApiQueryResponse) {
        state.detail.newVsReturning = data;
    },

    setDetailPriceTypesData(state, data: AnalyticsApiQueryResponse) {
        state.detail.priceTypes = data;
    },

    setDetailPriceTypesTableData(state, data: AnalyticsApiQueryResponse) {
        state.detail.priceTypesTable = data;
    },

    setDetailCustomersData(state, data: AnalyticsApiQueryResponse) {
        state.detail.customers = data;
    },

    setOccupancyTimeSeriesData(state, data: AnalyticsApiQueryResponse) {
        state.occupancy.timeSeries = data;
    },
    setOccupancyPerEventCategoryData(state, data: AnalyticsApiQueryResponse) {
        state.occupancy.perEventCategory = data;
    },
    setOccupancyPerTimeData(state, data: AnalyticsApiQueryResponse) {
        state.occupancy.perTime = data;
    },

    setVisitTimeData(state, data: AnalyticsApiQueryResponse) {
        state.visitTime = data;
    },

    setEventCategoriesData(state, data: AnalyticsApiQueryResponse) {
        state.eventCategory.eventCategories = data;
    },

    setEventPriceTypesData(state, data: AnalyticsApiQueryResponse) {
        state.eventPriceTypes.priceTypes = data;
    },

};
