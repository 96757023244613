import {ActionTree} from 'vuex';
import {FinancialState} from './models';
import {RootState} from '@/store/models';
import analyticsApi from '@/services/AnalyticsApiService';
import {AnalyticsApiQueryBuilder} from '@/util/AnalyticsApiQueryBuilder';


export const actions: ActionTree<FinancialState, RootState> = {

    async fetchSalesChannelData({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.salesChannel'],
            metrics: ['te.sold', 'te.reserved', 'te.inCart', 'te.abandoned', 'te.revenue'],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-behaviour-sales-channel');
        commit('setSalesChannelData', data);
    },

    async fetchSalesChannelLedgerData({commit, rootState}, {salesChannel}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            dimensions: ['te.salesChannel', 'te.transactionId', 'te.customer', 'te.priceType', 'te.register'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue'],
            filters: [{
                key: 'te.salesChannel',
                operator: 'equals',
                value: salesChannel,
            }, {key: 'te.transactionLineStatus', operator: 'equals', value: 'completed'}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-behaviour-sales-channel-ledger');
        commit('setSalesChannelLedgerData', data);
    },

    async fetchRegisterData({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.register'],
            metrics: ['te.sold', 'te.reserved', 'te.inCart', 'te.abandoned', 'te.revenue'],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-behaviour-register');
        commit('setRegisterData', data);
    },

    async fetchRegisterLedgerData({commit, rootState}, {register}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            dimensions: ['te.register', 'te.customer', 'te.priceType'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue'],
            filters: [{key: 'te.register', operator: 'equals', value: register}, {
                key: 'te.transactionLineStatus',
                operator: 'equals',
                value: 'completed',
            }],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-behaviour-register-ledger');
        commit('setRegisterLedgerData', data);
    },

    async fetchPriceTypesData({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.priceType'],
            metrics: ['te.sold', 'te.reserved', 'te.inCart', 'te.abandoned', 'te.revenue'],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine(queryBuilder.build(), 'get-behaviour-price-type');
        commit('setPriceTypesData', data);
    },

    async fetchEventPriceTypeData({ commit, rootState }, {eventStartDate}): Promise<void> {
        const filter = eventStartDate ? [{key: 'te.eventStartDate', operator: 'equals', value: eventStartDate}] : undefined;
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.priceType', 'te.eventTitle', 'te.eventLocation', 'te.eventStartDateHourMinute', 'te.eventId'],
            metrics: ['te.sold', 'te.reserved', 'te.revenue'],
            filters: filter,
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportOrderLine( queryBuilder.build(), 'get-event-price-type' );
        commit('setEventPriceTypesData', data);
    },


    async fetchPaymentTimeSeriesData({commit, rootState}, {timeDimension, metric}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: [timeDimension],
            metrics: [metric],
        });

        const data = await analyticsApi.reportPayment(queryBuilder.build(), 'get-behaviour-payment-time-series');
        commit('setPaymentTimeSeriesData', data);
    },

    async fetchPaymentMethodData({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.paymentMethod'],
            metrics: ['te.payments', 'te.paymentRevenue'],
            includeEmptyRows: true,
        });

        const data = await analyticsApi.reportPayment(queryBuilder.build(), 'get-behaviour-payment-method');
        commit('setPaymentMethodData', data);
    },

    async fetchPaymentServiceProviderData({commit, rootState}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            segments: rootState.selectedSegments,
            dimensions: ['te.paymentServiceProvider'],
            metrics: ['te.payments', 'te.paymentRevenue'],
            includeEmptyRows: true,
        });

        const data = await analyticsApi.reportPayment(queryBuilder.build(), 'get-behaviour-payment-psp');
        commit('setPaymentServiceProviderData', data);
    },

    async fetchPaymentMethodDetailData({commit, rootState}, {paymentMethod}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            dimensions: ['te.paymentMethod', 'te.currencyCode', 'te.customer', 'te.transactionDateHourMinute'],
            metrics: ['te.paymentRevenue'],
            filters: [{key: 'te.paymentMethod', operator: 'equals', value: paymentMethod}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportPayment(queryBuilder.build(), 'get-behaviour-payment-method-detail');
        commit('setPaymentMethodDetailData', data);
    },

    async fetchPaymentServiceProviderDetailData({commit, rootState}, {psp}): Promise<void> {
        const queryBuilder = new AnalyticsApiQueryBuilder({
            dateContext: rootState.dateContext,
            dimensions: ['te.paymentServiceProvider', 'te.currencyCode', 'te.customer', 'te.transactionDateHourMinute'],
            metrics: ['te.paymentRevenue'],
            filters: [{key: 'te.paymentServiceProvider', operator: 'equals', value: psp}],
            includeEmptyRows: false,
        });

        const data = await analyticsApi.reportPayment(queryBuilder.build(), 'get-behaviour-payment-psp-detail');
        commit('setPaymentServiceProviderDetailData', data);
    },



};
