import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { SegmentState } from './models';
import { RootState } from '@/store/models';

export const state: SegmentState = {
    segments: [],
    segment: null,
    integrations: [],
    integrationConfigs: [],
    mailchimpLists: [],
};

const namespaced: boolean = true;

export const segment: Module<SegmentState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
