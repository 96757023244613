import i18n from '@/i18n';
import {DateRangePreset} from '@/models/date-context';
import moment from 'moment';

export const dateRangePresets: DateRangePreset[] = [
    {
        id: 'last30Days',
        label: i18n.t('dateContext.presets.last30Days'),
        getDateRange: (dateRange) => {
            // const min = dateRange ? moment(dateRange.min).subtract(1, 'days').toDate() : moment().toDate();
            // const max = dateRange ? moment(dateRange.max).subtract(31   , 'days').toDate() : moment().toDate();
            const min = dateRange ? moment(dateRange.min).toDate() : moment().toDate();
            const max = dateRange ? moment(dateRange.max).toDate() : moment().toDate();
            return {
                min: moment(min).subtract(30, 'days').startOf('day').toDate(),
                max: moment(max).endOf('day').toDate(),
                // max: moment(max).add(1, 'days').endOf('day').toDate(),
            };
        },
    },
    {
        id: 'last7Days',
        label: i18n.t('dateContext.presets.last7Days'),
        getDateRange: (dateRange) => {
            // const min = dateRange ? moment(dateRange.min).subtract(1, 'days').toDate() : moment().toDate();
            // const max = dateRange ? moment(dateRange.max).subtract(8, 'days').toDate() : moment().toDate();
            const min = dateRange ? moment(dateRange.min).toDate() : moment().toDate();
            const max = dateRange ? moment(dateRange.max).toDate() : moment().toDate();
            return {
                min: moment(min).subtract(7, 'days').startOf('day').toDate(),
                max: moment(max).endOf('day').toDate(),
                // max: moment(max).add(1, 'days').endOf('day').toDate(),
            };
        },
    },
    {
        id: 'lastMonth',
        label: i18n.t('dateContext.presets.lastMonth'),
        getDateRange: (dateRange) => {
            const min = dateRange ? moment(dateRange.min).toDate() : moment().toDate();
            const max = dateRange ? moment(dateRange.max).toDate() : moment().toDate();
            return {
                min: moment(min).subtract(1, 'months').startOf('month').toDate(),
                max: moment(max).subtract(1, 'months').endOf('month').toDate(),
            };
        },
    },
    {
        id: 'lastWeek',
        label: i18n.t('dateContext.presets.lastWeek'),
        getDateRange: (dateRange) => {
            const min = dateRange ? moment(dateRange.min).toDate() : moment().toDate();
            const max = dateRange ? moment(dateRange.max).toDate() : moment().toDate();
            return {
                min: moment(min).subtract(1, 'weeks').startOf('week').toDate(),
                max: moment(max).subtract(1, 'weeks').endOf('week').toDate(),
            };
        },
    },
    {
        id: 'thisYear',
        label: i18n.t('dateContext.presets.thisYear'),
        getDateRange: (dateRange) => {
            const min = dateRange ? moment(dateRange.min).subtract(1, 'years').toDate() : moment().toDate();
            const max = dateRange ? moment(dateRange.max).subtract(1, 'years').toDate() : moment().toDate();
            return {
                min: moment(min).startOf('year').toDate(),
                max: moment(max).toDate(),
            };
        },
    },
    {
        id: 'lastYear',
        label: i18n.t('dateContext.presets.lastYear'),
        getDateRange: (dateRange) => {
            const min = dateRange ? moment(dateRange.min).subtract(1, 'years').toDate() : moment().toDate();
            const max = dateRange ? moment(dateRange.max).subtract(1, 'years').toDate() : moment().toDate();
            return {
                min: moment(min).startOf('year').subtract(1, 'years').toDate(),
                max: moment(max).endOf('year').subtract(1, 'years').toDate(),
            };
        },
    },
    {
        id: 'custom',
        label: i18n.t('dateContext.presets.custom'),
        getDateRange: (dateRange) => {
            // const min = dateRange ? moment(dateRange.min).subtract(1, 'days').toDate() : moment().toDate();
            const min = dateRange ? dateRange.min : moment().toDate();
            const max = dateRange ? moment(dateRange.max).subtract(7, 'days').toDate() : moment().toDate();
            return {
                min: moment(min).subtract(7, 'days').startOf('day').toDate(),
                max: moment(max).endOf('day').toDate(),
            };
        },
    },
];
