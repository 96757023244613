    import {ArrayConditionOption, FactOption, FactType, OperatorOption} from '@/models/condition';
import ConditionOptionService from '@/services/ConditionOptionService';
import i18n from '@/i18n/index';


export const groupOperators: OperatorOption[] = [
    {
        id: 'and',
        label: i18n.t('condition.operator.and'),
    },
    {
        id: 'or',
        label: i18n.t('condition.operator.or'),
    },
    {
        id: 'noneOf',
        label: i18n.t('condition.operator.noneOf'),
    },
];

export const numberOperators: OperatorOption[] = [
    {
        id: 'equals',
        label: i18n.t('condition.operator.equals'),
    },
    {
        id: 'gt',
        label: i18n.t('condition.operator.gt'),
    },
    {
        id: 'lt',
        label: i18n.t('condition.operator.lt'),
    },
];

export const stringOperators: OperatorOption[] = [
    {
        id: 'equals',
        label: i18n.t('condition.operator.equals'),
    },
    {
        id: 'notEquals',
        label: i18n.t('condition.operator.notEquals'),
    },
];

export const arrayOperators: OperatorOption[] = [
    {
        id: 'contains',
        label: i18n.t('condition.operator.contains'),
    },
    {
        id: 'notContains',
        label: i18n.t('condition.operator.notContains'),
    },
];

export const dateOperators: OperatorOption[] = [
    {
        id: 'equals',
        label: i18n.t('condition.operator.equals'),
    },
    {
        id: 'isBefore',
        label: i18n.t('condition.operator.isBefore'),
    },
    {
        id: 'isAfter',
        label: i18n.t('condition.operator.isAfter'),
    },
];

export const factOptions: FactOption[] = [
    // order
    {
        id: 'orderCount',
        label: i18n.t('condition.fact.orderCount'),
        type: FactType.number,
        operators: numberOperators,
    },
    {
        id: 'orderDate',
        label: i18n.t('condition.fact.orderDate'),
        type: FactType.date,
        operators: dateOperators,
    },
    {
        id: 'salesChannel',
        label: i18n.t('condition.fact.salesChannel'),
        type: FactType.array,
        operators: arrayOperators,
        options: ConditionOptionService.getSalesChannelOptions,
    },
    {
        id: 'averageAmountSpentPerOrder',
        label: i18n.t('condition.fact.averageAmountSpentPerOrder'),
        type: FactType.currencyAmount,
        operators: numberOperators,
        options: ConditionOptionService.getCurrencyOptions,
    },
    {
        id: 'amountSpentOnSingleOrder',
        label: i18n.t('condition.fact.amountSpentOnSingleOrder'),
        type: FactType.currencyAmount,
        operators: numberOperators,
        options: ConditionOptionService.getCurrencyOptions,
    },
    {
        id: 'totalAmountSpent',
        label: i18n.t('condition.fact.totalAmountSpent'),
        type: FactType.currencyAmount,
        operators: numberOperators,
        options: ConditionOptionService.getCurrencyOptions,
    },
    {
        id: 'firstOrderDate',
        label: i18n.t('condition.fact.firstOrderDate'),
        type: FactType.date,
        operators: dateOperators,
    },
    {
        id: 'lastOrderDate',
        label: i18n.t('condition.fact.lastOrderDate'),
        type: FactType.date,
        operators: dateOperators,
    },



    // access
    {
        id: 'accessCount',
        label: i18n.t('condition.fact.accessCount'),
        type: FactType.number,
        operators: numberOperators,
    },
    {
        id: 'averageAccessPerTransaction',
        label: i18n.t('condition.fact.averageAccessPerTransaction'),
        type: FactType.number,
        operators: numberOperators,
    },
    {
        id: 'averageAccessValue',
        label: i18n.t('condition.fact.averageAccessValue'),
        type: FactType.number,
        operators: numberOperators,
    },

    // event
    {
        id: 'eventCount',
        label: i18n.t('condition.fact.eventCount'),
        type: FactType.number,
        operators: numberOperators,
    },
    {
        id: 'eventDate',
        label: i18n.t('condition.fact.eventDate'),
        type: FactType.date,
        operators: dateOperators,
    },
    {
        id: 'eventVisitedDays',
        label: i18n.t('condition.fact.eventVisitedDays'),
        type: FactType.number,
        operators: numberOperators,
    },
    {
        id: 'lastVisitedEventDate',
        label: i18n.t('condition.fact.lastVisitedEventDate'),
        type: FactType.date,
        operators: dateOperators,
    },
    {
        id: 'nextEventDate',
        label: i18n.t('condition.fact.nextEventDate'),
        type: FactType.date,
        operators: dateOperators,
    },
    {
        id: 'averageSalesVelocityDays',
        label: i18n.t('condition.fact.averageSalesVelocityDays'),
        type: FactType.number,
        operators: numberOperators,
    },
    {
        id: 'visitedEvent',
        label: i18n.t('condition.fact.visitedEvent'),
        type: FactType.array,
        operators: arrayOperators,
        options: ConditionOptionService.getVisitedEventOptions,
    },
    {
        id: 'visitedEventCategory',
        label: i18n.t('condition.fact.visitedEventCategory'),
        type: FactType.array,
        operators: arrayOperators,
        options: ConditionOptionService.getVisitedEventCategoryOptions,
    },

    // period
    {
        id: 'periodCount',
        label: i18n.t('condition.fact.periodCount'),
        type: FactType.number,
        operators: numberOperators,
    },
    {
        id: 'visitedPeriod',
        label: i18n.t('condition.fact.visitedPeriod'),
        type: FactType.array,
        operators: arrayOperators,
        options: ConditionOptionService.getVisitedPeriodOptions,
    },

    // segment
    {
        id: 'segment',
        label: i18n.t('condition.fact.segment'),
        type: FactType.array,
        operators: arrayOperators,
        options: ConditionOptionService.getSegmentOptions,
    },
];
