import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';


Vue.config.productionTip = false;



// multiselect
import 'vue-multiselect/dist/vue-multiselect.min.css';


// // i18n
import i18n from '@/i18n/index';
// import VueI18n from 'vue-i18n';
// import { messages, defaultLocale } from '@/i18n';
// Vue.use(VueI18n);
// export const i18n = new VueI18n({
// // const i18n = new VueI18n({
//   messages,
//   locale: defaultLocale,
//   fallbackLocale: defaultLocale,
// });
// console.log(i18n);


// icons
import Unicon from 'vue-unicons';
// @ts-ignore
import {uniPlusCircle, uniMinusCircle, uniUsersAlt, uniHeadSide, uniChannel, uniChannelAdd, uniCheck, uniTimes, uniAngleUp, uniAngleDown, uniTicket, uniEllipsisV, uniInfoCircle, uniExport, uniBill, uniDashboard, uniEuroCircle, uniFileMedicalAlt} from 'vue-unicons/src/icons';

Unicon.add([
    uniPlusCircle,
  uniMinusCircle,
  uniUsersAlt,
  uniHeadSide,
  uniChannel,
  uniChannelAdd,
  uniCheck,
  uniTimes,
  uniAngleUp,
  uniAngleDown,
  uniTicket,
  uniEllipsisV,
  uniInfoCircle,
  uniExport,
    uniBill,
    uniDashboard,
  uniEuroCircle,
  uniFileMedicalAlt
]);
Vue.use(Unicon);





// modal
import VModal from 'vue-js-modal';
Vue.use(VModal);


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
