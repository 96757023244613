import {GetterTree} from 'vuex';
import {Job, RootState} from '@/store/models';
import {NavigationGroup} from '@/models/navigation';
import {FactOption, OperatorOption} from '@/models/condition';
import {SegmentSummery} from '@/models/segment';
import {
    DateContext,
    DateContextEvent,
    DateContextPeriod,
    DateContextTransaction,
    DateRangePreset, instanceOfDateContextPeriod,
    Period,
} from '@/models/date-context';
import {Currency} from '@/models/currency';
import analyticsApi from '@/services/AnalyticsApiService';
import {Report} from '@/models/report';

export const getters: GetterTree<RootState, RootState> = {
    scopes(state): string[] {
        return analyticsApi.getAuthScopes();
    },

    jobs(state): Job[] {
        return state.jobs;
    },

    inEditMode(state): boolean {
        return state.inEditMode;
    },

    isEditor(): boolean {
        return analyticsApi.getAuthScopes().includes('analytics:write');
    },

    navigation(state): NavigationGroup[] {
        return state.navigation;
    },

    factOptions(state): FactOption[] {
        return state.factOptions;
    },

    groupOperators(state): OperatorOption[] {
        return state.groupOperators;
    },

    segments(state): SegmentSummery[] {
        return state.segments;
    },

    selectedSegments(state): SegmentSummery[] {
        return state.segments.filter((s) => state.selectedSegments.includes(s.id));
    },

    dateRangePresets(state): DateRangePreset[] {
        return state.dateRangePresets;
    },

    periods(state): Period[] {
        return state.periods;
    },

    // dateContext(state): DateContext|DateContextPeriod|DateContextEvent|DateContextTransaction|null {
    dateContext(state): DateContextPeriod|DateContextEvent|DateContextTransaction|undefined|null {
        return state.dateContext;
    },

    dateDimensionName(state): string {
        if (instanceOfDateContextPeriod(state.dateContext)) {
            return 'te.period';
        }
        return 'te.dateRange';
    },

    defaultCurrency(state): Currency {
        return state.defaultCurrency;
    },

    reports(state): Report[] {
        return state.reports;
    },
};
