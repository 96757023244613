import {Module} from 'vuex';
import {RootState} from '@/store/models';
import {BehaviourState} from '@/modules/behaviour/store/models';
import {getters} from '@/modules/behaviour/store/getters';
import {actions} from '@/modules/behaviour/store/actions';
import {mutations} from '@/modules/behaviour/store/mutations';


export const state: BehaviourState = {
    overview: {
        kpi: null,
        timeSeries: null,
        priceType: null,
        salesChannel: null,
        register: null,
    },
    kpi: null,
    timeSeries: null,
    salesChannel: null,
    salesChannelLedger: null,
    register: null,
    registerLedger: null,
    purchaseTime: null,
    priceType: null,
    paymentTimeSeries: null,
    paymentMethod: null,
    paymentServiceProvider: null,
    paymentMethodDetail: null,
    paymentServiceProviderDetail: null,
};

const namespaced: boolean = true;

export const behaviour: Module<BehaviourState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};

